import * as React from "react";
import { ButtonLink } from "../atoms";
import { UniversalLink } from "../global";

const Share = () => {
  return (
    <section className={"section max-w-5xl mx-auto text-center"} id={"share"}>
      <h2 className={"h2"}>
        Share with us!{" "}
        <span role={"img"} aria-label={"hands-emoji"}>
          {" "}
          🙌️{" "}
        </span>
      </h2>
      <p className={"max-w-lg mx-auto mt-2"}>
        Have a reason you love Beaver Dam that you just can’t keep to yourself
        any longer? Let it out! There are three ways to share.
      </p>
      <div className={"flex flex-wrap mt-8"}>
        <ShareCard>
          <ShareCardTitle>
            Social Media
            <span role={"img"} aria-label={"phone-emoji"}>
              {" "}
              📱{" "}
            </span>
          </ShareCardTitle>
          <ShareCardText>
            Post on social media and tell us why you love BD! Mention{" "}
            <strong>@IHeartBD</strong> on Facebook or use the hashtag{" "}
            <strong>#iheartbd</strong> on Instagram or Twitter to have your
            message included.
          </ShareCardText>
          <ShareCardButton url={"/testimonials"}>
            View all submissions{" "}
          </ShareCardButton>
        </ShareCard>
        <ShareCard>
          <ShareCardTitle>
            Photos
            <span role={"img"} aria-label={"camera-emoji"}>
              {" "}
              📷{" "}
            </span>
          </ShareCardTitle>
          <ShareCardText>
            Submit your photo by mentioning <strong>@IHeartBD</strong> on
            Facebook or by using the <strong>#iheartbd</strong> hashtag on
            Instagram. Alternatively, submit your photo{" "}
            <UniversalLink
              url={"/contact"}
              className={"border-b border-primary"}
            >
              via our form
            </UniversalLink>
            .
          </ShareCardText>
          <ShareCardButton url={"/contact"}>Submit a photo</ShareCardButton>
        </ShareCard>
        <ShareCard>
          <ShareCardTitle>
            Stories
            <span role={"img"} aria-label={"writing-emoji"}>
              {" "}
              ✍️{" "}
            </span>
          </ShareCardTitle>
          <ShareCardText>
            Do you
            <span role={"img"} aria-label={"heart-emoji"}>
              {" "}
              ❤️️{" "}
            </span>
            BD so much that you can’t limit your love to a couple short
            sentences? Submit a story via the same social media methods or by{" "}
            filling out{" "}
            <UniversalLink
              url={"/contact"}
              className={"border-b border-primary"}
            >
              our form
            </UniversalLink>
            . Stories can also contain images!
          </ShareCardText>
          <ShareCardButton url={"/contact"}>Submit a story</ShareCardButton>
        </ShareCard>
      </div>
    </section>
  );
};

const ShareCard = ({ children }) => (
  <div className={"w-full md:w-1/3 max-w-md mx-auto p-3"}>
    <div
      className={
        "h-full flex flex-col bg-white shadow rounded space-y-6 px-3 py-5"
      }
    >
      {children}
    </div>
  </div>
);

const ShareCardTitle = ({ children }) => <h3 className={"h3"}>{children}</h3>;
const ShareCardText = ({ children }) => (
  <p className={"flex-grow text-sm text-gray-700"}>{children}</p>
);
const ShareCardButton = ({ children, url }) => (
  <ButtonLink alt url={url}>
    {children}
  </ButtonLink>
);

export default Share;
