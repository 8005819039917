import * as React from "react";
import { UniversalLink, UniversalLinkProps } from "../global";
import { ReactNode, ReactNodeArray } from "react";

interface ButtonLinkProps {
  alt?: boolean;
  children: ReactNode | ReactNodeArray;
  className?: string;
  url: UniversalLinkProps["url"];
}
export const ButtonLink = ({
  alt,
  children,
  className,
  url,
}: ButtonLinkProps) => {
  const primaryClassName =
    "shadow text-green-100 bg-primary hover:bg-green-900 focus:border-green-300 focus:shadow-outline-green active:bg-green-200";
  const altClassName =
    "shadow text-gray-700 bg-gray-200 hover:bg-gray-300 focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-300";

  return (
    <UniversalLink
      url={url}
      className={`${className} ${
        alt ? altClassName : primaryClassName
      } inline-block text-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md cursor-pointer focus:outline-none transition ease-in-out duration-150`}
    >
      {children}
    </UniversalLink>
  );
};
