import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Share from "../components/sections/Share";
import { ButtonLink } from "../components/atoms";
import { graphql } from "gatsby";
import Img from "gatsby-image";

const StoriesPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={"Stories"} />
      <section className={"section container mx-auto text-center"}>
        <h1 className={"sr-only"}>Stories</h1>
        <p className={"h1 block"}>
          Stories submitted by the community
          <span role={"img"} aria-label={"rock-on-emoji"}>
            {" "}
            🤘🏼{" "}
          </span>
        </p>
        <div
          className={
            "container mx-auto flex flex-wrap justify-around py-8 px-4"
          }
        >
          {data.allFile.edges.map(({ node }) => (
            <div className={"w-full md:w-1/2 p-4"} key={node.id}>
              <StoryCard
                url={node.childMarkdownRemark.fields.slug}
                date_published={
                  node.childMarkdownRemark.frontmatter.date_published
                }
                title={node.childMarkdownRemark.frontmatter.title}
                featured_image={
                  node.childMarkdownRemark.frontmatter.featured_image
                }
                excerpt={node.childMarkdownRemark.excerpt}
              />
            </div>
          ))}
        </div>
      </section>
      <div className={"mt-8"}>
        <Share />
      </div>
      <div className={"pb-8"} />
    </Layout>
  );
};

const StoryCard = ({ url, title, date_published, featured_image, excerpt }) => (
  <div className="max-w-sm mx-auto rounded overflow-hidden shadow-lg text-left">
    <Img
      className="w-full"
      fluid={{ ...featured_image.childImageSharp.fluid, aspectRatio: 2 }}
      alt="Sunset in the mountains"
    />
    <div className="px-6 py-4">
      <h2 className="font-bold text-xl">{title}</h2>
      <div className="text-sm tracking-wide">{date_published}</div>
      <p className="text-base leading-tight mt-4">{excerpt}</p>
    </div>
    <div className="px-6 py-4 text-right">
      <ButtonLink url={url}>Read More</ButtonLink>
    </div>
  </div>
);

export const storiesPageQuery = graphql`
  query StoriesPageQuery {
    allFile(filter: { relativeDirectory: { eq: "stories" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            excerpt
            frontmatter {
              title
              date_published(formatString: "MMMM Do, YYYY")
              featured_image {
                childImageSharp {
                  fluid(maxWidth: 600, cropFocus: ATTENTION) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`;

export default StoriesPage;
